import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import FeaturedProducts from '../components/FeaturedProducts'
import LogoBanner from '../components/LogoBanner'
import NextSteps from '../components/NextSteps'
import ProductList from '../components/ProductList';

const ProductListPage = ({ data: { prismicProductListPage } }) => {
  const { data } = prismicProductListPage
  return (
    <Layout>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />

      <FeaturedProducts
        featuredHeading={data.featured_heading.text}
        products={data.featured}
      />

      <Container>
        <LogoBanner />
        <ProductList
          listHeading={data.software_heading.text}
          products={data.software}
        />
        <ProductList
          listHeading={data.hardware_heading.text}
          products={data.hardware}
        />
      </Container>
     
      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }
    </Layout>
  )
}

export default ProductListPage

export const pageQuery = graphql`
  query ProductListPageQuery {
    prismicProductListPage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        featured_heading {
          text
        }
        featured {
          heading {
            text
          }
          image {
            url
          }
          description {
            html
          }
          link_label {
            text
          }
          link {
            url
          }
        }
        software_heading {
          text
        }
        software {
          heading {
            text
          }
          description {
            html
          }
          link_label {
            text
          }
          link {
            url
          }
          image {
            url
          }
        }
        hardware_heading {
          text
        }
        hardware {
          heading {
            text
          }
          description {
            html
          }
          link_label {
            text
          }
          link {
            url
          }
          image {
            url
          }
        }
        next_steps {
          heading {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`